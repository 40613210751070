<template>
  <v-app @contextmenu.prevent.stop>
    <v-bottom-navigation fixed color="primary">
      <v-row align="center" justify="center">
        <v-col cols="12" md="4">
          <div id="buttons" class="d-flex mx-auto">
            <v-btn @click="page > 1 ? page-- : 1">
              <span>Back</span>

              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>

            <v-btn>
              <span>{{ page }} / {{ numPages ? numPages : "∞" }}</span>
            </v-btn>

            <v-btn @click="page < numPages ? page++ : 1">
              <span>Forward</span>

              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </v-col>

        <v-col cols="12" md="6">
          <div class="text-center read-title text-h6 font-weight-black">
            Judul Buku
          </div>
          <div class="text-center read-title text-subtitle-2">Nama Penulis</div>
        </v-col>
      </v-row>
    </v-bottom-navigation>
    <pdf
      :src="pdfdata"
      v-for="i in numPages"
      :key="i"
      :id="i"
      :page="i"
      :scale.sync="scale"
      style="width: 100%; margin: 20px auto"
      :annotation="true"
      :resize="true"
      @link-clicked="handle_pdf_link"
      @contextmenu.prevent.stop
    >
      <template slot="loading"> loading content here... </template>
    </pdf>

    <v-dialog v-model="confirmation" max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Continue Reading? </v-card-title>

        <v-card-text>
          Previously you have read up to page <strong>3</strong>, Continue
          reading?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" text @click="confirmation = false">
            Disagree
          </v-btn>

          <v-btn color="primary" text @click="goToPage(3)"> Agree </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import pdfvuer from "pdfvuer";
import "pdfjs-dist/build/pdf.worker.entry"; // not needed since v1.9.1
import axios from "axios";

export default {
  components: {
    pdf: pdfvuer,
  },
  data() {
    return {
      page: 1,
      numPages: 0,
      pdfdata: undefined,
      errors: [],
      scale: 1024,

      data: [],

      confirmation: false,
      blobed: "",
    };
  },
  computed: {
    formattedZoom() {
      return Number.parseInt(this.scale * 100);
    },
  },
  mounted() {
    this.getData();
  },
  watch: {
    show: function (s) {
      if (s) {
        this.getPdf();
      }
    },
    page: function (p) {
      if (
        window.pageYOffset <= this.findPos(document.getElementById(p)) ||
        (document.getElementById(p + 1) &&
          window.pageYOffset >= this.findPos(document.getElementById(p + 1)))
      ) {
        // window.scrollTo(0,this.findPos(document.getElementById(p)));
        document.getElementById(p).scrollIntoView();
      }
    },
  },
  methods: {
    showContextMenu(e) {
      e.preventDefault();
      alert("Context Menu");
    },
    goToPage(pageNumber) {
      var page = document.getElementById(String(pageNumber));
      page.scrollIntoView();

      this.confirmation = false;
    },
    handle_pdf_link: function (params) {
      // Scroll to the appropriate place on our page - the Y component of
      // params.destArray * (div height / ???), from the bottom of the page div
      var page = document.getElementById(String(params.pageNumber));
      page.scrollIntoView();
    },
    getData() {
      this.loadingData = true;

      axios
        .get("customer/read/" + this.$route.params.id, {
          headers: {
            "Access-Control-Allow-Headers": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((response) => {
          console.log(response);
          this.data = response.data.data.data;
          this.loadingData = true;

          this.getPdf();
        })

        .catch((error) => {
          console.log(error);
          this.submitForm = false;
        });
    },
    getPdf() {
      var self = this;
      self.pdfdata = pdfvuer.createLoadingTask(this.data.naskah.market.ebook);
      self.pdfdata.then((pdf) => {
        self.numPages = pdf.numPages;
        window.onscroll = function () {
          changePage();
          stickyNav();
        };
        this.confirmation = true;

        // Get the offset position of the navbar
        var sticky = $("#buttons")[0].offsetTop;

        // Add the sticky class to the self.$refs.nav when you reach its scroll position. Remove "sticky" when you leave the scroll position
        function stickyNav() {
          if (window.pageYOffset >= sticky) {
            $("#buttons")[0].classList.remove("hidden");
          } else {
            $("#buttons")[0].classList.add("hidden");
          }
        }

        function changePage() {
          var i = 1,
            count = Number(pdf.numPages);
          do {
            if (
              window.pageYOffset >= self.findPos(document.getElementById(i)) &&
              window.pageYOffset <= self.findPos(document.getElementById(i + 1))
            ) {
              self.page = i;
            }
            i++;
          } while (i < count);
          if (window.pageYOffset >= self.findPos(document.getElementById(i))) {
            self.page = i;
          }
        }
      });
    },
    findPos(obj) {
      return obj.offsetTop;
    },
  },
};
</script>
<style src="pdfvuer/dist/pdfvuer.css"></style>
<style lang="css" scoped>
#buttons {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
/* Page content */
.content {
  padding: 16px;
}
</style>
